
export const environment = {
  production: true,
  firebase: {
    apiKey: 'undefined',
    authDomain: 'portfolio-a8df4.firebaseapp.com',
    databaseURL: 'https://portfolio-a8df4.firebaseio.com',
    projectId: 'portfolio-a8df4',
    storageBucket: 'portfolio-a8df4.appspot.com',
    messagingSenderId: '924371232404',
    appId: '1:924371232404:web:ff5d898070c2426d'
  }
};
